import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import Headline from '../components/headline/Headline'
import Accordion from '../components/accordion/Accordion'
import Container from '../components/container/Container'
import Text from '../components/text/Text'
import RandomImages from '../components/randomImages/RandomImages'
import Helmet from 'react-helmet'

const Page = ({data: {about}, pageContext}) => {

    if (!about) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >
            <Helmet>
                <title>{`Aquilas — ${about.data.headline.text}`}</title>
            </Helmet>

            <Headline headline={about.data.headline}/>
            {about.data.body && about.data.body.map((slice, index) => {
                switch (slice.__typename) {
                    case 'PrismicAboutBodyText':
                        return (
                            <Container key={index}>
                                <Text text={slice.primary.text}/>
                            </Container>
                        )
                    case 'PrismicAboutBodyAccordion':
                        return (
                            <Accordion
                                key={index}
                                items={slice.items}
                                text={slice.primary.text}
                                color={slice.primary.color}
                            />
                        )
                    default:
                        return null
                }
            })}
            <RandomImages images={about.data.random_images}/>
        </Layout>
    )
}

export const query = graphql`
query aboutQuery($lang: String!) {
  about: prismicAbout(lang:{eq:$lang }) {
    data {
      headline {
        text
      }
      random_images {
        image {
          url
          dimensions {
            width
            height
          }
        }
      }
      body {
        __typename
        ... on PrismicAboutBodyAccordion {
          primary{
            color
            text {
            html
            }
          }
          items {
            title {
              text
            }
            text {
              html
            }
          }
        }
      }
    }
  }
}
`

export default Page
