import React from 'react'
import styles from './Accordion.module.scss'
import Text from '../text/Text'
import cx from 'classnames'
import {AppConsumer} from '../../AppProvider'
import {CURSORS} from '../../Config'

class AccordionItem extends React.Component {

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
        this.showCursor = this.showCursor.bind(this)
        this.hideCursor = this.hideCursor.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isToggleOn && this.props.isToggleOn) {
            this.props.showCursor(CURSORS.ARROW_UP)
            this.node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
    }

    handleClick() {
        this.props.onToggle(this.props.index)
    }

    showCursor() {
        this.props.showCursor(this.props.isToggleOn ? CURSORS.ARROW_UP : CURSORS.ARROW_DOWN)
    }

    hideCursor() {
        this.props.hideCursor()
    }

    render() {
        const {title, text, isToggleOn} = this.props
        return (
            <div
                className={cx(styles.Item)}
                ref={node => this.node = node}
                onMouseEnter={this.showCursor}
                onMouseLeave={this.hideCursor}
                onClick={this.handleClick}>

                <h4
                    className={styles.Title}
                    dangerouslySetInnerHTML={{__html: title}}/>

                <div className={cx(styles.Panel, {[styles.IsVisible]: isToggleOn})}>
                    <Text text={text}/>
                </div>
            </div>
        )
    }
}

const WrappedElement = props => (
    <AppConsumer>
        {({showCursor, hideCursor}) => <AccordionItem {...props} showCursor={showCursor} hideCursor={hideCursor}/>}
    </AppConsumer>
)

export default WrappedElement