import React from 'react'
import styles from './Accordion.module.scss'
import PropTypes from 'prop-types'
import Text from '../text/Text'
import cx from 'classnames'
import AccordionItem from './AccordionItem'

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isToggleOn: -1
    }

    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle(index) {
    if (index === this.state.isToggleOn) {
      this.setState({isToggleOn: -1})
    } else {
      this.setState({isToggleOn: index})
    }
  }

  render() {
    const { items, color, text } = this.props
    const { isToggleOn } = this.state
    const accordionItems = items.map((item, index) =>
      <AccordionItem
        key={index}
        index={index}
        title={item.title.text}
        text={item.text}
        isToggleOn={isToggleOn === index}
        onToggle={this.handleToggle}/>
    )

    return (
      <div className={cx(styles.Main, styles[color])}>
        <div className={styles.Inner}>
          <div className={styles.TextContainer}>
            <Text text={text}/>
          </div>
          <div className={styles.Items}>
            {accordionItems}
          </div>
        </div>
      </div>
    )
  }
}

export default Accordion

Accordion.propTypes = {
  color: PropTypes.string,
  items: PropTypes.array,
  text: PropTypes.shape({
    html: PropTypes.string,
  }),
}

Accordion.defaultProps = {
  color: 'White',
  items: [],
  text: { html: '' },
}